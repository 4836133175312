<template>
  <div class="content">
    <div class="content-inner">
      <h1 class="title">About Us</h1>
      <el-divider class="divider" />

      <div class="content-text">
        Agriva Rummy is an Online Game which is operated by Ranch Rt Private
        Limited for all rummy enthusiasts, offering multiple online carrom and
        rummy variants with the added guarantee of safety, security, and fair
        play.
        <br />
        Indian Rummy is interesting and challenging. It requires skill, use of
        memory and complete focus and involvement in the game.
        <br /><br />
        <strong> Combination of tradition and modernity </strong>
        <br /><br />
        Agriva Rummy is based on the traditional rummy game which was originated
        in India even played by Kings and Queens in the ancient times. It has
        been a part of people's lifestyle since then. Technology has enabled
        players to adapt this game in a modern context and made it more flexible
        and easier.
        <br /><br />
        <strong> Play with Friends </strong>
        <br /><br />
        Agriva Rummy is the most thrilling card game for your Android devices
        with Millions of real Players and friends.
        <br />
        Each table Serves upto 5 players online so Invite your facebook Friends
        &amp; Family to play.
        <br /><br />
        <strong> Realistic experience </strong>
        <br /><br />
        Agriva Rummy will let multiple players compete with each other at the
        same time. The attributes of this game make it enjoyable at every level,
        unlike the traditional card game, this game lets you enjoy without any
        involvement of money. At the same time, it gives you the same thrill you
        get through the real card game.
        <br />
        Try out your luck on our Deluxe Agriva Rummy! In short, you'll love this
        game as it is the top online free rummy game.
        <br /><br />
        <strong>Cool Game Features</strong>
        <br />
        <br />
        - Play online with your friends and online users.<br />
        - User Friendly Graphical User Interface.<br />
        - Smoothly works on 2G, 3G and WiFi connections.<br />
        - Easy to play and understand<br />
        - Less traffic usage<br />
      </div>
    </div>
  </div>
</template>

<style scoped>
.content {
  background: #f3f3f3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-bottom: 60px;
  padding-top: 100px;
  min-height: 600px;
}
.content-inner {
  width: 89%;
  height: auto;
  background-color: #fff;
  border-radius: 10px 9px 9px 9px;
  margin-top: 31px;
}
.title {
  color: #6622ab;
  text-align: center;
}
.divider {
  width: 100%;
}
.content-text {
  padding: 15px 15px;
}
</style>
