<template>
  <div class="content">
    <div class="content-inner">
      <h1 class="title">Legality</h1>
      <el-divider class="divider" />

      <div class="content-text">
        Is it Legal to play Agriva rummy on https://www.agrivaimpex.com in
        India?<br /><br />
        Yes, it is absolutely legal to play Agriva rummy on
        https://www.agrivaimpex.com in India. Apart from being Legal as per the
        Indian Supreme Court ruling – The Game of Rummy is a game of skill and
        not a game of chance. As such it is protected under the Constitution of
        India under Article 19[1] (g).<br /><br />
        Further, in the year 1968, the Supreme Court of India ruled that rummy
        is a game of skill and its decision has been relied on in various
        subsequent judgments.<br /><br />
        In another judgment in 1996, the Supreme Court also stated that (i)
        competitions where success depends on substantial degree of skill and
        (ii) despite there being an element of chance, if a game is
        preponderantly a game of skill it may be called a game of "mere
        skill".<br /><br />
        Therefore, it is safe to say that playing Agriva rummy on
        https://www.agrivaimpex.com in India is a legal activity.<br /><br />

        Agriva rummy does not allow players from Karnataka, Meghalaya, Andhra
        Pradesh, Assam, Odisha, Sikkim, Nagaland,Tamil Nadu, Arunachal Pradesh,
        and Telangana to play or win our cash games.<br /><br />
      </div>
    </div>
  </div>
</template>

<style scoped>
.content {
  background: #f3f3f3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-bottom: 60px;
  padding-top: 100px;
  min-height: 600px;
}
.content-inner {
  width: 89%;
  height: auto;
  background-color: #fff;
  border-radius: 10px 9px 9px 9px;
  margin-top: 31px;
}
.title {
  color: #6622ab;
  text-align: center;
}
.divider {
  width: 100%;
}
.content-text {
  padding: 0 15px;
}
</style>
