<template>
  <div class="content">
    <div class="content-inner">
      <h1 class="title">Privacy Policy</h1>
      <el-divider class="divider" />

      <div class="content-text">
        <strong> PRIVACY POLICY &amp; LEGALITY </strong>
        <br /><br />
        This Privacy Policy &amp; Legality forms part of our TERMS AND
        CONDITIONS, by accepting the TERMS AND CONDITIONS and the PRIVACY POLICY
        &amp; LEGALITY on the registration page, you consent to provide
        sensitive personal data or personal information and are aware of the
        purpose of sharing such information. Please do not register if you do
        not wish to share the mandatory personal information with Ranch Rt.
        Registration procedure cannot be completed until information in the
        non-optional fields is provided. Certain additional personal information
        may be requested by Ranch Rt for permitting you access to any Games
        organized by Ranch Rt.
        <br /><br />
        <strong> POLICY OBJECTIVE </strong>
        <br /><br />
        Ranch Rt respects your privacy and assures you that any information
        provided by you to Ranch Rt is protected and will be dealt with
        according to this Policy and the applicable laws. To avail the services
        offered on https://www.agrivaimpex.com// (hereinafter singly referred as
        the "Website" and collectively referred as "Websites"), you may have to
        provide certain information to us. This Policy provides the procedure
        followed by Ranch Rt to gather, uses, store, discloses and manages
        users' personal data. Ranch Rt only collects personal data for the
        purpose of verifying user accounts, maintaining the accounts of the
        users, completing transactions of the users and for analyzing user
        behavior and requirements.
        <br /><br />
        <strong>WHAT IS INCLUDED IN PERSONAL DATA</strong>
        <br /><br />
        User's personal data includes the following types of personal data or
        information:https://www.agrivaimpex.com/
        <br />
        Sensitive Personal Data: Account password, Financial information such as
        Bank account or credit card or debit card or other payment instrument
        details. Other Personal Information: Name Date of birth, Telephone
        number, Postal/Contact address, PAN number (as applicable), The IP
        address of your computer, Browser type and language. The date and the
        time during which you accessed the site. The address of the website
        which you may have used to link to any Website, Your photograph for
        testimonials and other promotions. Such other information that is
        defined as sensitive personal data or information in law.
        <br /><br />
        <strong>USER CONSENTS</strong>
        <br /><br />
        Consent for use of Sensitive Personal Data and Other Personal
        Information:
        <br /><br />
        All users of Ranch Rt consent to the use of Sensitive Personal Data and
        Other Personal Information for the purpose stated in this policy. We
        restrict access of personal information to our employees, contractors
        and agents and only allow access to those who need to know that
        information in order to process it on our behalf.
        <br /><br />
        Users are entitled to withdraw consent for use of their Sensitive
        Personal Data or Other Personal Information by emailing a specific
        request to HappyTabassum01@gmail.com, as the case may be. Withdrawal of
        consent of all or any part of Sensitive Personal Data and Other Personal
        Information may result in immediate withdrawal of any right to avail the
        services provided by Ranch Rt. Withdrawal of consent shall not restrict
        the right of Ranch Rt to use Other Personal Information for analysis of
        user data, subject to the condition that the Other Personal Information
        used in any analysis is not personally identifiable to any individual
        user.
        <br /><br />
        Photographs once provided by you for marketing purposes gives Ranch Rt
        irrevocable rights to use it and it is completely Ranch Rt discretion to
        delete or not use it any further.
        <br /><br />
        Ranch Rt may also use software applications for website traffic analysis
        and to gather statistics, used for advertising and for determining the
        efficacy and popularity of Ranch Rt among others.
        <br /><br />
        Presently, Ranch Rt is using display advertising and remarketing through
        Google Analytics, wherein third party vendors including google, display
        our ads on sites across the internet and uses first party cookies and
        third party cookies together to inform, optimize and serve ads based on
        visits of visitors to our website. Our visitors are free to opt out of
        Google Analytics for display advertising and customize google display
        network ads using ads preference manager.
        <br /><br />
        <strong>CONSENT TO USE OF COOKIES</strong>
        <br /><br />
        What is a Cookie: A cookie is a small text file that uniquely identifies
        your browser.
        <br /><br />
        You understand that when you visit the Website, cookies may be left in
        your computer. The cookies assigned by the servers of Ranch Rt may be
        used to personalize your experience on the Website. Additionally,
        cookies may also be used for authentication, game management, data
        analysis and security purposes.
        <br /><br />
        Cookies may also be assigned by the advertisers of Ranch Rt when you
        click on any of the advertisements which may be displayed on any Website
        in which case such cookies are controlled by these advertisers and not
        Ranch Rt.
        <br /><br />
        <strong>CONSENT TO EMAIL COMMUNICATION</strong>
        <br /><br />
        When you register your email address with Ranch Rt, you agree to receive
        email communication from Ranch Rt, entities specifically authorized by
        Ranch Rt and other users. You also agree and acknowledge that when you
        use our referral program for referring someone, Ranch Rt will send
        emails to such person referred by you on your behalf and the email
        headers will carry your email address as the address from which such
        emails are sent.
        <br /><br />
        Ranch Rt may also access and store such information relating to your
        contacts to send invitations and other promotions to them periodically.
        <br /><br />
        <strong>RULES AND RESTRICTIONS</strong>
        <br /><br />
        Failure to follow restrictions or terms of service can get User account
        suspended, hacked or permanently banned. Please read the following
        restrictions which applies to use of the service:
        <br /><br />
        We recommend that minors over the age of 13 and below the age of 18
        shall ask their parents for permission before sending any information
        about themselves to anyone over the Internet. User must not use the
        service if User are under the age of 13. User must deny anyone under 13
        to use User account. User are fully responsible for any unauthorized use
        of the service including not limited to the use of credit card or any
        payment by any method.
        <br /><br />
        User shall use User account only for non-commercial entertainment
        purposes. User shall not use the service for any other purpose, such as
        collecting chips from various accounts or by any other means.
        <br /><br />
        User shall not use User account for any illegal activity.
        <br /><br />
        User shall not use User account to transmit repetitive messages (spam),
        junk e-mail, advertise and solicit.
        <br /><br />
        User shall not use profanity or language that discriminates or
        maliciously targets another individual in any way. This includes User
        profile picture.
        <br /><br />
        User shall not use User account to cheat or hack the game by any means.
        Losing on purpose as a means of transferring chips can be tracked
        easily, and this behaviour will have User chips taken and banned.
        <br /><br />
        User shall not sublicense, lease, trade, gift, sell or otherwise
        transfer User account or associated virtual items partly or fully to
        anyone without written permission from Ranch Rt.
        <br /><br />
        User shall not buy or get chips, bonus and any other virtual items from
        any unauthorized source such as links from unknown sources or people
        promising free chips, etc.
        <br /><br />
        Users are allowed one account per social network to use the service. In
        addition, Users cannot use multiple accounts to try to accumulate free
        chips. This behavior is easily detectible and will result in all User
        accounts being suspended and/or banned.
        <br /><br />
        Users shall not use scripts and manual processes to abusively accumulate
        free chips.
        <br /><br />
        Users shall not use any of the services or create an account or use an
        account if Users have been removed or banned from using the service.
        <br /><br />
        Users shall not use any of the Ranch Rt game or service if Users do not
        agree with the terms of service and User license to use the service
        shall immediately terminate.
        <br /><br />
        The User has no right to carry out actions including but not limited to:
        <br /><br />
        Deleting all the information and contents related the copyright on Ranch
        Rt software and other copies;
        <br /><br />
        Performing reverse engineering, reverse assembly, reverse compilation,
        etc., to the game of the Ranch Rt, regardless of any purpose of
        plagiarizing or changing the game software of Ranch Rt;
        <br /><br />
        For information related to the software of game of the Ranch Rt (without
        the written consent of Ranch Rt) the user cannot (without authorization
        including but not limited to) use, copy, modify, link, reprint,
        assemble, release, publish, set up a mirror site, or use Ranch Rt game
        for these purposes without authorization to develop derivative products,
        works or services related to the software.
        <br /><br />
        The User is not allowed to host, post, display, upload, modify, copy,
        transmit, publish, update, share or store the following information
        using Ranch Rt' s game:
        <br /><br />
        Information in violation of any law and regulation, department
        regulation or local applicable law.
        <br /><br />
        Endangering national security, disclosing state secrets, subverting
        state power and undermining state unification.
        <br /><br />
        Damaging national honor and interests.
        <br /><br />
        Instigating ethnic hatred, ethnic discrimination and undermining ethnic
        unity.
        <br /><br />
        Destroying religious policies of the State, promoting malevolent cults
        and arcane superstitions.
        <br /><br />
        Insulting or slandering others and infringing on the lawful rights and
        interests of others.
        <br /><br />
        Infringing on the intellectual property rights, trade secrets or other
        legal rights of others.
        <br /><br />
        Information that harms the minor in any manner.
        <br /><br />
        Information that deceives or misleads the addressee about the origin of
        such messages or communicates any information which is grossly offensive
        or menacing in nature.
        <br /><br />
        Information that contains software viruses or any other computer code,
        files or programs designed to interrupt, destroy or limit the
        functionality of any computer resource
        <br /><br />
        Information that threatens the unity, integrity, defence, security or
        sovereignty of counties where the games are played by the User, friendly
        relations with foreign states, or public order or causes incitement to
        the commission of any cognizable offence or prevents investigation of
        any offence or is insulting any other nation.
        <br /><br />
        Ranch Rt reserves the right to terminate the license of any user.
        <br /><br />
        Governing Law and Jurisdiction:
        <br /><br />
        If any provision set forth in this Privacy Policy is held invalid, in
        part or in whole, it shall not prejudice the validity of the remaining
        provisions of this Privacy Policy.
        <br /><br />
        The validity, performance, interpretation and dispute settlement of this
        Privacy Policy and any non-contractual obligations arising out of or in
        connection with it shall be governed by the laws of countries where the
        games are being played by the Users.
        <br /><br />
        Any dispute arising out of the content or enforcement of this Privacy
        Policy shall first be settled by the concerned parties through friendly
        consultation. If the aforesaid dispute cannot be resolved by the parties
        within 30 (thirty) days of such dispute arising, then the dispute shall
        be resolved by way of arbitration pursuant to the provisions of the
        applicable laws of the host country where the games are being played.
        <br /><br />
        The seat and venue of the arbitration shall be at the place where the
        registered office of Ranch Rt is situated. Subject to aforesaid
        arbitration provisions, either party may initiate a lawsuit over such
        dispute in the relevant Court with jurisdiction at the place where the
        registered office of Ranch Rt is situated.
        <br /><br />
        <strong>DATA SECURITY</strong>
        <br /><br />
        We take appropriate security measures to protect against unauthorized
        access to or unauthorized alteration, disclosure or destruction of data.
        These include internal reviews of our data collection, storage and
        processing practices and security measures, including appropriate
        encryption and physical security measures to guard against unauthorized
        access to systems where we store personal data. Ranch Rt has a
        comprehensive information security program and information security
        policies which contain managerial, technical, operational and physical
        security control measures adopted by Ranch Rt for the protection of
        Sensitive Personal Date and Other Personal Information.
        <br /><br />
        Information gathered by Ranch Rt is stored securely using several
        information security applications including firewalls. However, security
        is always relative and Ranch Rt cannot guarantee that its security
        measures are absolute and cannot be breached. Data which is transmitted
        over the Internet is inherently exposed to security risks or threats.
        For instance, information transmitted via chat or email can be
        compromised and used by others. Therefore, Ranch Rt cannot guarantee any
        security for such information in the course of transmission through the
        internet infrastructure or any unsolicited disclosures made by any user
        availing the services of the Website.
        <br /><br />
        When you register with Ranch Rt, your account is protected by means of
        login information which includes a username and a password that is known
        only to you. Therefore, you should not provide your personal information
        to anyone whosoever and breach hereof constitutes violation of this
        Policy and can also result in closure of account in certain cases. If
        you become aware of or reasonably suspect any breach of security,
        including compromise of your login information, it is your
        responsibility to immediately notify Ranch Rt.

        <br /><br />
        The Websites may contain links to other websites. Such websites are
        governed by their own privacy policies &amp; Legality and Ranch Rt does
        not exercise any control over them. It is your responsibility to read
        and understand the Privacy Policy &amp; Legality of such websites when
        you follow a link outside the Website. You are advised to exercise
        caution in sharing any personal information with any third party that
        advertises on the Websites and Ranch Rt shall not be responsible for
        such information provided by you on third party websites.
        <br /><br />
        Ranch Rt has a policy of not sharing any personally identifiable
        information with anyone other than entities specifically authorized by
        Ranch Rt which may include advertisers and sponsors of Ranch Rt.
        However, Ranch Rt may use your name, photo, Login ID and the state from
        where you are participating when announcing the results of any contests
        run on the Website. Such contests are further governed by Terms and
        Conditions which shall be available on the Websites as and when such a
        contest is run on any Website. Ranch Rt conducts periodic analysis and
        survey of the traffic to https://www.agrivaimpex.com/ for market
        research and advertising purposes. Ranch Rt reserves the right to share
        your registration information with Ranch Rt appointed market research
        and advertising companies or firms from time to time for the said
        purposes. Ranch Rt may also use cumulative non-personal information for
        auditing and analysis purposes with the aim of improving its services.
        <br /><br />
        <strong>EXCLUSIONS</strong>
        <br /><br />
        Ranch Rt may share Sensitive Personal Data and Other Personal
        Information if sharing of such information is necessary: to comply with
        legal processes or governmental request, to enforce the Terms and
        Conditions and this Privacy Policy &amp; Legality, for prevention of
        fraud, for issues involving information security, to protect your
        rights, rights of Ranch Rt and rights of the general public.
        <br /><br />
        <strong>LIMITATION OF LIABILITY</strong>
        <br /><br />
        Ranch Rt confirms that this Privacy Policy &amp; Legality is only a
        description of its operation regarding user information. This Policy is
        not intended to and does not create any legal rights in your favour or
        in the favour of any other person. Ranch Rt reserves the right to change
        this Policy at any time without giving you prior notice. The liability
        of Ranch Rt shall be limited to removal of Sensitive Personal Data from
        the system of the Websites and removal of personally identifiable
        elements of the Other Personal Information. Notwithstanding anything to
        the contrary contained in this Policy and elsewhere, the aggregate
        liability of Ranch Rt for all claims for damages pursuant to provisions
        of services on the Website, including claims in respect to the violation
        of this Policy, shall be limited to the aggregate maximum amount of
        liability as provided in the Terms and Conditions.
      </div>
    </div>
  </div>
</template>

<style scoped>
.content {
  background: #f3f3f3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-bottom: 60px;
  padding-top: 100px;
  min-height: 600px;
}
.content-inner {
  width: 89%;
  height: auto;
  background-color: #fff;
  border-radius: 10px 9px 9px 9px;
  margin-top: 31px;
}
.title {
  color: #6622ab;
  text-align: center;
}
.divider {
  width: 100%;
}
.content-text {
  padding: 15px 15px;
}
</style>
