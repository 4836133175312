<template>
  <div :class="['menu-item', { active: isActive }]" @click="handleClick">
    <router-link :to="`/${menu.toLowerCase()}`">{{ menuname }}</router-link>
  </div>
</template>

<script setup>
import { computed, toRefs } from "vue";
import { useRouter } from "vue-router";
const props = defineProps({
  menu: String,
  menuname: String,
  activeMenu: String,
});
const emit = defineEmits(["menu-click"]);
const router = useRouter();

const isActive = computed(() => props.activeMenu === props.menu);

const handleClick = () => {
  emit("menu-click", props.menu);
  router.push(`/${props.menu.toLowerCase()}`);
};
</script>

<style lang="scss" scoped>
.menu-item a {
  text-decoration: none;
}
.menu-item {
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
  a {
    color: white;
  }
}

.menu-item.active {
  background-color: #edf437;
  a {
    color: black;
  }
}

.menu-item:hover {
  background-color: #edf437;
  a {
    color: black;
  }
}
</style>
