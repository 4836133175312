<template>
  <div class="content">
    <div class="content-inner">
      <h1 class="title">Contact Us</h1>
      <el-divider class="divider" />

      <div class="content-text">
        <strong>You can contact with us through</strong><br /><br />

        RANCH RT PRIVATE LIMITED<br /><br />

        Phone: 8712012101<br /><br />

        Email: info@agrivaimpex.com<br /><br />

        Address: H NO. 434A, 4TH FLOOR, SHREE JHULE LAL MARG, SHIV MARKET, Ashok
        Vihar Phase 1, New Delhi, North West Delhi- 110052<br /><br />
      </div>
    </div>
  </div>
</template>

<style scoped>
.content {
  background: #f3f3f3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-bottom: 60px;
  padding-top: 100px;
  min-height: 600px;
}
.content-inner {
  width: 89%;
  height: auto;
  background-color: #fff;
  border-radius: 10px 9px 9px 9px;
  margin-top: 31px;
}
.title {
  color: #6622ab;
  text-align: center;
}
.divider {
  width: 100%;
}
.content-text {
  padding: 15px 15px;
}
</style>
