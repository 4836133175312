<template>
  <div class="content">
    <div class="content-inner">
      <h1 class="title">TERMS OF SERVICE</h1>
      <el-divider class="divider" />

      <div class="content-text">
        <strong>ELIGIBILITY</strong>
        <br /><br />
        A person who is 18 or more years of age is eligible to use our services,
        however, if a person violates age restrictions, it’s his/her sole
        responsibility to bear the legal actions that might follow. User is
        required to understand that our services are available only in India. We
        don’t offer our services in the states of Andhra Pradesh, Arunachal
        Pradesh, Telangana, Tamil Nadu, Assam, Orissa, Nagaland, Sikkim, Kerala
        and Meghalaya in India. We have the right to control the access to our
        services at all times, in all States in India. These terms of use shall
        be suitably modified to reflect changes in law/regulation/rule in any of
        the states in India and the user shall be notified of the same in the
        event of such change.
        <br /><br />
        <strong>GAME OF SKILL </strong>
        <br /><br />
        It is absolutely legal to play rummy in India as it falls under the
        ‘game of skill’ category which means the outcome of the game is
        dependent upon skills and not luck. These terms and conditions create a
        contract between you (“the user” or “the player”) and Ranch Rt Private
        Limited(hereinafter referred as “Ranch Rt” or “we” or “us” “our”).
        Please read the Agreement carefully. To confirm your understanding and
        acceptance of the Agreement.
        <br /><br />
        <strong>INTRODUCTION TO OUR SERVICES</strong>
        <br /><br />
        This Agreement governs your use of RANCH RT’s services (“Services”),
        through which you can buy, get, license, rent or subscribe to content,
        apps (“Apps”), and other in-app services (collectively, “Content”).
        Content may be offered through the Services by Ranch Rt or a third
        party. Our Services are available for your use in your country of
        residence (“Home Country”). To use our Services, you need compatible
        hardware, software (latest version recommended and sometimes required)
        and Internet access (fees may apply). Our Services’ performance may be
        affected by these factors.
        <br /><br />
        You must be age 18 (or equivalent minimum age in your Home Country, as
        set forth in the registration process) to create an Ranch Rt ID and use
        our Services. Ranch Rt IDs for persons under this age can be created by
        a parent or legal guardian using Family Sharing or by an approved
        educational institution.
        <br /><br />
        <strong>PRIVACY POLICY &amp; LEGALITY</strong>
        <br /><br />
        Your use of our Services is subject to RANCH RT’s Privacy Policy &amp;
        Legality, which is available at PRIVACY POLICY &amp; LEGALITY.
        <br /><br />
        <strong>SERVICES AND CONTENT USAGE RULES</strong>
        <br /><br />
        Your use of the Services and Content must follow the rules set forth in
        this section (“Usage Rules”). Any other use of the Services and Content
        is a material breach of this Agreement. Ranch Rt may monitor your use of
        the Services and Content to ensure that you are following these Usage
        Rules.
        <br /><br />
        <strong>USE OF SERVICE RESTRICTIONS</strong>
        <br /><br />
        1. You must not use the Service is you are under the age of 18. You must
        deny anyone under 18 to use your account. You are fully responsible for
        any unauthorized use of the Service including not limited to the use of
        credit card or any payment by any method.
        <br /><br />
        2. You shall use your account only for non-commercial entertainment
        purposes. You shall not use the Service for any other purpose.
        <br /><br />
        3. You shall not use your account for any illegal activity.
        <br /><br />
        4. You shall not use your account to transmit repetitive messages
        (spam), junk e-mail, advertise and solicit.
        <br /><br />
        5. You shall not post any objectionable and offensive information
        including but not limited to abusive, threatening, racial, sexual, or
        obscene.
        <br /><br />
        6. You will not use your account to cheat or hack the game by any means.
        <br /><br />
        7. You shall not sublicense, lease, trade, gift, sell or otherwise
        transfer your account or associated virtual items partly or fully to
        anyone without written permission from Ranch Rt.
        <br /><br />
        8. You shall not buy or purchase chips and virtual items from any
        unauthorized source.
        <br /><br />
        9. You shall not create or use more than one Facebook account to use the
        Service.
        <br /><br />
        10. You shall not use any of the Services or create an account or use an
        account if you have been removed or banned from using the Service.
        <br /><br />
        11. You shall not any of the Ranch Rt services if you do not agree with
        the Terms of Service, Privacy Policy &amp; Legality or any other policy,
        and your license to use the services shall immediately terminate.
      </div>
    </div>
  </div>
</template>

<style scoped>
.content {
  background: #f3f3f3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-bottom: 60px;
  padding-top: 100px;
  min-height: 600px;
}
.content-inner {
  width: 89%;
  height: auto;
  background-color: #fff;
  border-radius: 10px 9px 9px 9px;
  margin-top: 31px;
}
.title {
  color: #6622ab;
  text-align: center;
}
.divider {
  width: 100%;
}
.content-text {
  padding: 15px 15px;
}
</style>
