<template>
  <nav class="navbar">
    <div class="header-title">
      <!-- 手机端 Logo + 菜单按钮 -->
      <div class="mobile-navbar" v-if="isMobile">
        <el-dropdown trigger="click" placement="bottom-start">
          <el-icon color="#fff" :size="35"><Operation /></el-icon>
          <!-- <el-button icon="el-icon-menu" circle></el-button> -->
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                v-for="(menu, index) in allMenus"
                :key="index"
                @click="$router.push(menu.path)"
                >{{ menu.name }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <div @click="$router.push('/')" class="mobile-logo">
          <img src="@/assets/logo.png" alt="Logo" />
        </div>
      </div>

      <!-- 桌面端菜单和 Logo -->
      <div class="desktop-navbar" v-else>
        <div class="menu">
          <MenuItem
            v-for="(menu, index) in leftMenus"
            :key="index"
            :menu="menu.path"
            :menuname="menu.name"
            @menu-click="setActiveMenu"
            :activeMenu="activeMenu"
          />
        </div>
        <div @click="$router.push('/')" class="logo">
          <img src="@/assets/logo.png" alt="Logo" />
        </div>
        <div class="menu">
          <MenuItem
            v-for="(menu, index) in rightMenus"
            :key="index"
            :menu="menu.path"
            :menuname="menu.name"
            @menu-click="setActiveMenu"
            :activeMenu="activeMenu"
          />
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { ref, onMounted } from "vue";
import MenuItem from "./MenuItem.vue";
import { Operation } from "@element-plus/icons-vue";
import { useWindowSize } from "@vueuse/core"; // 引入 vueuse 库来监听窗口大小

const isMobile = ref(false);

const leftMenus = [
  { name: "Home", path: "Home" },
  { name: "Refunds/Cancellations", path: "Refunds" },
  { name: "Terms & Conditions", path: "Terms" },
];
const rightMenus = [
  { name: "Legality", path: "Legality" },
  { name: "Privacy Policy", path: "Privacy" },
  { name: "Contact Us", path: "Contact" },
];
const allMenus = [...leftMenus, ...rightMenus];
console.log(allMenus);
const activeMenu = ref("Home");

// 激活菜单
const setActiveMenu = (menu) => {
  activeMenu.value = menu;
};

// 根据窗口大小判断是否是手机端
const { width } = useWindowSize();
onMounted(() => {
  isMobile.value = width.value <= 768; // 设置一个宽度阈值，判断是否是手机端
});
</script>

<style lang="scss" scoped>
.navbar {
  position: fixed;
  width: 100%;
  height: 80px;
  z-index: 999;
}
.header-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: #230143;
  height: 80px;
}

/* 桌面端样式 */
.desktop-navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.menu {
  display: flex;
}

.mobile-logo {
  width: 34.58vw;
  height: 8.75vw;
  margin-right: auto;
  margin-left: 22.92vw;
  -webkit-box-pack: center;
  img {
    width: 86%;
    height: auto;
    margin-right: 5.14vw;
  }
}
.logo {
  width: 11.88vw;
  height: 3.49vw;
  img {
    width: 100%;
    height: auto;
    margin-right: 5.14vw;
  }
}

.menu-item {
  margin: 0 15px;
}

/* 手机端样式 */
.mobile-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.el-drawer__body {
  background-color: #444;
}

.el-button {
  color: white;
  border-color: white;
}

.el-dropdown-menu__item {
  display: flex;
  justify-content: center;
}
</style>
