<template>
  <footer class="footer">
    <div class="footer-content">
      <el-row style="width: 100%; height: 100%">
        <el-col :xs="24" :sm="12">
          <div class="footer-left">
            <div class="column">
              <div @click="$router.push('About')">About Us</div>
              <div @click="$router.push('Privacy')">Privacy Policy</div>
              <div @click="$router.push('Terms')">Terms & Conditions</div>
            </div>
            <div class="column">
              <div @click="$router.push('Refunds')">Refunds Cancellations</div>
              <div @click="$router.push('Legality')">Legality</div>
              <div @click="$router.push('Contact')">Contact Us</div>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="12">
          <div class="footer-right">
            <el-divider class="divider" direction="vertical" />
            <div class="image-container">
              <img src="@/assets/footer.png" alt="Image 1" />
              <img src="@/assets/footer1.png" alt="Image 2" />
              <img src="@/assets/footer2.png" alt="Image 3" />
              <img src="@/assets/footer3.png" alt="Image 4" />
              <img src="@/assets/footer4.png" alt="Image 5" />
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </footer>
  <div class="reserved">
    © 2024 RANCH RT PRIVATE LIMITED | All Rights Reserved
  </div>
</template>
<style lang="scss" scoped>
.footer {
  background-color: #3f0477;
  color: white;
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  height: 100%;
}
.footer-left {
  display: flex;
  align-items: center;
  justify-content: space-between; /* 保证左右分布 */
  height: 100%;
  color: #fff;
  font-weight: 400;
  font-size: 25px;
}
.column {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.column div {
  margin-bottom: 15px;
}

.column div:last-child {
  margin-bottom: 0;
}

.footer-right {
  display: flex;
  align-items: center;
  height: 100%;
}

.image-container {
  display: grid;
  gap: 20px;
  margin-left: 60px;
}
.image-container img {
  width: 100%;
  height: auto;
}
.divider {
  margin-left: 60px;
  height: 80%;
}
.reserved {
  width: 100%;
  background-color: #230143;
  text-align: center;
  color: #fff;
  font-size: 13px;
  padding: 6px 0;
}

/* PC 端显示 2x2 布局 */
@media screen and (min-width: 769px) {
  .image-container {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
  }
}

/* 移动端显示 3x2 布局 */
@media screen and (max-width: 768px) {
  .image-container {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, auto);
    margin-left: 0;
  }
  .divider {
    display: none;
  }
  .footer-left {
    font-size: 15px;
  }
}
</style>
