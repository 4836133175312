import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import Refunds from "@/views/Refunds.vue";
import Terms from "@/views/Terms.vue";
import Legality from "@/views/Legality.vue";
import Privacy from "@/views/Privacy.vue";
import Contact from "@/views/Contact.vue";
import About from "@/views/About.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/home", // Add this line to support /home
    name: "homeAlias",
    component: Home,
  },
  {
    path: "/Refunds",
    name: "refunds",
    component: Refunds,
  },
  {
    path: "/Terms",
    name: "terms",
    component: Terms,
  },
  {
    path: "/Legality",
    name: "legality",
    component: Legality,
  },
  {
    path: "/Privacy",
    name: "privacy",
    component: Privacy,
  },
  {
    path: "/Contact",
    name: "contact",
    component: Contact,
  },
  {
    path: "/about",
    name: "About Us",
    component: About,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
