<template>
  <div class="content">
    <div class="content-inner">
      <h1 class="title">Refunds/Cancellations</h1>
      <el-divider class="divider" />

      <div class="content-text">
        <strong>The conditions for refund</strong><br /><br />
        1. The payment is successful but not received by the player.<br /><br />
        2. Due to app technology problems, it can not provide game services for
        players for a long time.<br /><br />
        3. Due to payment loopholes, players pay repeatedly.<br /><br />
        <strong>How to refund</strong><br /><br />
        1. Contact customer service via WhatsApp or email to describe the
        problem.<br /><br />
        2. The customer service staff will reply you as soon as possible and
        actively assist in solving the problem.<br /><br />
        3. If your problem is not properly resolved. We will initiate a refund
        for you.<br /><br />
        4. Usually it will be returned to your fund account within 5 working
        days.<br /><br />
        <strong>The following scenarios you can't refund</strong><br /><br />
        1. Recharge has arrived, and you have consumed part of it.<br /><br />
        2. Payment time over 3 days.<br /><br />
        3. There is clear evidence that you cheat in the game and cause harm to
        other players, so limit your trading.
      </div>
    </div>
  </div>
</template>

<style scoped>
.content {
  background: #f3f3f3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-bottom: 60px;
  padding-top: 100px;
  min-height: 600px;
}
.content-inner {
  width: 89%;
  height: auto;
  background-color: #fff;
  border-radius: 10px 9px 9px 9px;
  margin-top: 31px;
}
.title {
  color: #6622ab;
  text-align: center;
}
.divider {
  width: 100%;
}
.content-text {
  padding: 15px 15px;
}
</style>
