<template>
  <div class="banner-box">
    <a href="/AgrivaRummy.apk">
      <img
        style="width: 100%; height: auto; display: block"
        src="@/assets/homebg.png"
    /></a>
    <div class="banner-feature">
      <div class="feature-item">
        <div class="banner-title">1</div>
        <div class="feature-text">
          <span>Sign Up for</span>
          <span>Free</span>
        </div>
      </div>
      <div class="feature-item">
        <div class="banner-title">2</div>
        <div class="feature-text">
          <span>Get 100%</span>
          <span>Bonus upto Rs.1500</span>
        </div>
      </div>
      <div class="feature-item">
        <div class="banner-title">3</div>
        <div class="feature-text">
          <span>Win Real Cash</span>
          <span>Everyday</span>
        </div>
      </div>
    </div>
    <div class="marquee-container">
      <div class="marquee">
        <span>{{ text }}</span>
      </div>
    </div>
    <div class="game-advantage">
      <el-row :gutter="30" :justify="center" :align="center">
        <el-col
          :xs="24"
          v-for="(item, index) in items"
          :key="index"
          :sm="8"
          class="grid-item"
        >
          <div class="item-content">
            <div class="advantage-icon">
              <img :src="item.icon" />
            </div>
            <div style="text-align: left">
              <div class="advantage-title">{{ item.title }}</div>
              <div class="right-box">
                <div class="right-icon">
                  <img src="@/assets/tick.png" />
                </div>
                <span class="right-content">{{ item.content1 }}</span>
              </div>
              <div class="right-box">
                <div class="right-icon">
                  <img src="@/assets/tick.png" />
                </div>
                <span class="right-content">
                  {{ item.content2 }}
                </span>
              </div>
              <div class="right-box">
                <div class="right-icon">
                  <img src="@/assets/tick.png" />
                </div>
                <span class="right-content"> {{ item.content3 }} </span>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="game-intro">
      <div class="intro-title">Rummy Game Introduction</div>
      <el-row :gutter="30">
        <el-col :xs="24" :sm="6">
          <div class="intro-icon">
            <img src="@/assets/picture_1.png" />
          </div>
          <div class="intro-content">
            <div class="intro-text">
              <div class="intro-text-title">Leaderboard Winners</div>
              <span>
                The leaderboard is updated right after each game and tells you
                about your rank.
              </span>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="6">
          <div class="intro-icon">
            <img src="@/assets/picture_2.png" />
          </div>
          <div class="intro-content">
            <div class="intro-text">
              <div class="intro-text-title">Challenge Real Players!</div>
              <span>
                Play with real players on exciting rummy! Unlimited fun and
                without additional costs!
              </span>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="6">
          <div class="intro-icon">
            <img src="@/assets/picture_3.png" />
          </div>
          <div class="intro-content">
            <div class="intro-text">
              <div class="intro-text-title">Variety of Contests</div>
              <span>
                Striving to offer a world-class rummy experience to our users,
                play with Friends on offer.
              </span>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="6">
          <div class="intro-icon">
            <img src="@/assets/picture_4.png" />
          </div>
          <div class="intro-content">
            <div class="intro-text">
              <div class="intro-text-title">Exciting Bonus</div>
              <span>
                Play Rummy & Win Crores of Rupees Daily, & Make Instant
                Withdrawals.
              </span>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="game-phone">
      <div class="game-phone-bg">
        <div style="padding: 30px; height: 45vw">
          <el-row :gutter="30">
            <el-col :xs="24" :sm="8">
              <div class="phone-left">
                <img src="@/assets/phone.png" />
              </div>
            </el-col>
            <el-col
              :xs="24"
              :sm="16"
              style="display: flex; align-items: center"
              :justify="center"
              :align="center"
            >
              <el-row>
                <el-col xs="24" :sm="12" class="phone-right">
                  <div class="phone-content">
                    <div class="phone-icon">
                      <img src="@/assets/icon_1.png" />
                    </div>
                    <div class="phone-title">Easy & Fun</div>
                  </div>
                  <div class="phone-text">
                    Enjoy the full game with real players! Play more, level up &
                    get exciting instant cashback
                  </div>
                </el-col>
                <el-col xs="24" :sm="12" class="phone-right">
                  <div class="phone-content">
                    <div class="phone-icon">
                      <img src="@/assets/icon_1.png" />
                    </div>
                    <div class="phone-title">Biggest Welcome Bonus</div>
                  </div>
                  <div class="phone-text">
                    Get 200% Bonus up to Rs.1000. Register now and get welcome
                    bonus
                  </div>
                </el-col>
                <el-col xs="24" :sm="12" class="phone-right">
                  <div class="phone-content">
                    <div class="phone-icon">
                      <img src="@/assets/icon_1.png" />
                    </div>
                    <div class="phone-title">Easy Withdrawal</div>
                  </div>
                  <div class="phone-text">
                    Hassle free & easy withdrawal. Money in minutes to your Bank
                    Account.
                  </div>
                </el-col>
                <el-col xs="24" :sm="12" class="phone-right">
                  <div class="phone-content">
                    <div class="phone-icon">
                      <img src="@/assets/icon_1.png" />
                    </div>
                    <div class="phone-title">Multiple Payment Options</div>
                  </div>
                  <div class="phone-text">
                    All major credit/debit cards accepted, Net banking from 50+
                    banks & wallets
                  </div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="mid-banner">
      <img src="@/assets/banner_mid.png" />
    </div>
    <div class="play-game">
      <div style="padding: 40px 0">
        <div class="play-game-title">How Game Works</div>
        <div class="play-game-text">
          Show Your Skills With Real Money & Win Cash Daily
        </div>
      </div>
      <div style="display: flex; justify-content: center">
        <div style="width: 90%">
          <el-row :gutter="30">
            <el-col xs="24" :sm="8">
              <div class="game-step-icon">
                <img src="@/assets/icon_12.png" />
              </div>
              <div class="game-step-title">Download Game</div>
              <div class="game-step-detail">
                Easy Download, Install Directly on your modile phone.
              </div>
            </el-col>
            <el-col xs="24" :sm="8">
              <div class="game-step-icon">
                <img src="@/assets/icon_13.png" />
              </div>
              <div class="game-step-title">Start Playing</div>
              <div class="game-step-detail">
                Easy Download, Install Directly on your modile phone.
              </div>
            </el-col>
            <el-col xs="24" :sm="8">
              <div class="game-step-icon">
                <img src="@/assets/icon_14.png" />
              </div>
              <div class="game-step-title">Win Real Money</div>
              <div class="game-step-detail">
                Win & Get Real Money in your e-wallet Wallet.
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="last-card">
      <el-row :gutter="30">
        <el-col xs="24" :sm="8">
          <div class="last-card-top">
            <span>
              It’s the perfect place to win real money with your rummy skills!
              The chances of winning money are much more than the other rummy
              sites.
            </span>
          </div>
          <div class="last-card-bottom">
            <img src="@/assets/avatar_1.png" />
            <div class="last-card-bottom-text">
              <div class="last-card-bottom-title">Amit Pradhan</div>
              <span> 9:07pm </span>
            </div>
          </div>
        </el-col>
        <el-col xs="24" :sm="8">
          <div class="last-card-top">
            <span>
              Rummy is the most genuine of all rummy sites, it has the fastest
              withdrawal services and VIP support resolves any query instantly.
            </span>
          </div>
          <div class="last-card-bottom">
            <img src="@/assets/avatar_2.png" />
            <div class="last-card-bottom-text">
              <div class="last-card-bottom-title">Vishal Nile</div>
              <span> 11:31pm </span>
            </div>
          </div>
        </el-col>
        <el-col xs="24" :sm="8">
          <div class="last-card-top">
            <span>
              Awesome, never thought I could win Monthly Million Tournament. I
              congratulate and thank that they provide every detail on their
              screen so nobody misses it.
            </span>
          </div>
          <div class="last-card-bottom">
            <img src="@/assets/avatar_3.png" />
            <div class="last-card-bottom-text">
              <div class="last-card-bottom-title">Deepak Sahu</div>
              <span> 08:54pm </span>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  name: "Home",
  data() {
    return {
      text: "Attention!Users from Karnataka, Meghalaya, Andhra Pradesh, Assam, Odisha, Sikkim, Nagaland,Tamil Nadu, Arunachal Pradesh, and Telangana, please Click Here!",
      items: [
        {
          icon: require("@/assets/icon_6.png"),
          title: "Daily Cash Prizes",
          content1: "Free sign up bonus",
          content2: "Login daily & get bonus upto Rs.50",
          content3: "Win real cash everyday",
        },
        {
          icon: require("@/assets/icon_10.png"),
          title: "Play Favourite Rummy Games",
          content1: "Strict & safe fair play policy",
          content2: "Anti-Fraud Detection",
          content3: "Pre-defined Daily Deposit Limit",
        },
        {
          icon: require("@/assets/icon_7.png"),
          title: "VIP Player Service",
          content1: "24x7 In-App Customer",
          content2: "Instant withdrawal support",
          content3: "Dedicated Customer Support",
        },
        {
          icon: require("@/assets/icon_9.png"),
          title: "Ultimate Rummy Experiences",
          content1: "50 Mn+ Players Zero Waiting Time",
          content2: "Rummy game table",
          content3: "Smooth Gameplay",
        },
        {
          icon: require("@/assets/icon_11.png"),
          title: "100% Safe & Secure",
          content1: "Complete Data and Identity Privacy",
          content2: "100% Payment Security",
          content3: "SSL Enabled Sysyem",
        },
        {
          icon: require("@/assets/icon_8.png"),
          title: "Guaranted Bonus Everytime",
          content1: "2500 Welcome Bonus",
          content2: "Up to 30% Bonus All Deposits",
          content3: "Monthly Instant Cashback Deals",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.banner-box {
  padding-top: 80px;
}
.banner-feature {
  width: 100%;
  height: 5.83vw;
  background-color: #6622ab;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  display: flex;
  align-items: center;
}
.banner-title {
  font-size: 4.8vw;
  letter-spacing: -0.12vw;
  color: #edf437;
  margin-right: 0.89vw;
}
.feature-item {
  display: flex;
  align-items: center;
}
.feature-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 1.56vw;
  line-height: 1.56vw;
  letter-spacing: -0.04vw;
  color: #fff;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
}

.marquee-container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  padding: 10px;
}

.marquee {
  display: inline-block;
  animation: marquee 30s linear infinite;
  width: 120vw;
  height: 5vw;
  font-size: 1.5vw;
  cursor: pointer;
  text-align: right;
  white-space: nowrap;
  color: red;
  position: relative;
  top: 2vw;
  right: -62vw;
}

.marquee-container:hover .marquee {
  animation-play-state: paused;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.game-advantage {
  width: 100%;
  max-width: 75%;
  margin: 0 auto;
  padding: 20px;
}
.grid-item {
  text-align: center;
}

.item-content {
  display: flex;
  align-items: center;
  margin-bottom: 2.92vw;
}
.advantage-title {
  font-size: 24px;
  font-weight: 700;
  color: #5801a8;
}
.advantage-icon {
  margin-right: 20px;
}
.right-box {
  text-align: left;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
}
.right-icon {
  margin-right: 10px;
  height: 17px;
  line-height: 17px;
}
.right-content {
  font-size: 18px;
  letter-spacing: -0.03vw;
  color: #404040;
  margin-top: 0.5vw;
}
.game-intro {
  background-color: #6622ab;
  margin-bottom: 2.34vw;
  width: 100%;
  max-width: 75%;
  margin: 0 auto;
  padding: 20px;
}
.intro-title {
  font-size: 23px;
  line-height: 1.62vw;
  letter-spacing: 0.03vw;
  color: #fff;
  text-align: center;
  font-weight: 700;
}
.intro-icon {
  width: 320px;
  margin-top: 30px;
  height: 181px;
}
.intro-content {
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 0;
  color: #404040;
  height: 181px;
  width: 320px;
  background-color: #fff;
  border-bottom-right-radius: 0.52vw;
  border-bottom-left-radius: 0.52vw;
}
.intro-text {
  width: 290px;
  text-align: left;
  padding-top: 20px;
  padding-left: 10px;
}
.intro-text-title {
  width: 320px;
}
.game-phone {
  display: flex;
  justify-content: center;
}
.game-phone-bg {
  margin-top: 30px;
  width: 100%;
  max-width: 73.6%;
  height: 866px;
  background-image: url("@/assets/game_phone_bg.png");
}

.phone-left {
  margin-right: 30px;
  margin-bottom: 10px;
}
.phone-left img {
  width: 100%;
  height: auto;
}
.phone-right {
  margin-bottom: 150px;
}
.phone-content {
  display: flex;
  align-items: center;
  text-align: center;
  height: 46px;
}
.phone-icon img {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
.phone-title {
  font-size: 25px;
  font-weight: 700;
  color: #5801a8;
  margin-left: 10px;
}

.mid-banner {
  width: 100%;
  max-width: 75%;
  margin: 40px auto;
}

.mid-banner img {
  width: 100%;
  height: auto;
}

.play-game {
  background-color: #f8fafe;
  width: 100%;
  max-width: 75%;
  height: 475px;
  margin: 0 auto;
}
.play-game-title {
  text-align: center;
  color: #000;
  font-size: 25px;
  font-weight: 700;
}
.play-game-text {
  text-align: center;
  color: #404040;
  font-size: 21px;
  font-weight: 700;
  margin-bottom: 30px;
}
.game-step-icon {
  width: 100%;
  height: auto;
  text-align: center;
}
.game-step-title {
  color: #5801a8;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}
.game-step-detail {
  color: #404040;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 30px;
}
.last-card {
  width: 100%;
  max-width: 75%;
  height: 475px;
  margin: 40px auto;
}
.last-card-top {
  height: 267px;
  background-color: #f0ebfe;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0 50px;
}
.last-card-top span {
  color: #3d3d3d;
  font-weight: 700;
  font-size: 20px;
}
.last-card-bottom {
  margin: 20px 0;
  display: flex;
  align-items: center;
}
.last-card-bottom-text {
  padding-left: 20px;
}

.last-card-bottom-title {
  color: #000;
  font-size: 26px;
  font-weight: 700;
}
.last-card-bottom-text span {
  color: #000;
  font-size: 22px;
}
@media (max-width: 800px) {
  .game-phone-bg {
    height: 1330px;
    max-width: 84%;
  }
  .mid-banner {
    max-width: 84%;
  }
  .play-game {
    max-width: 84%;
    height: 1000px;
  }

  .last-card {
    max-width: 84%;
    height: 1300px;
  }

  .phone-title {
    font-size: 18px;
  }

  .phone-right {
    margin-bottom: 90px;
  }
}
</style>
